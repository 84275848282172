<template>
    <Page
        class="md:w-full"
        :title="$t('renewal.menu')"
    >
        <div class="form-field">
            <div>
                <p class="font-bold">Unit 324</p>
                <p class="font-bold">Dear 'Resident 1'</p>
            </div>
        </div>
        <div class="form-field">
            <div>
                <p>
                    The staff at 'Community Name' have been honored to have you as a resident and community member since 'original move-in
                    date'. We know that you have a choice in where you live, so thank you for choosing to call 'Community Name' home, and
                    thank you for helping to make this such a wonderful community!
                    <br /><br />
                    A review of our records shows that your current lease will expire on 'LeaseEndDate'. We are happy to present you with
                    several options to assist you in renewing your lease agreement with us. Please insdicate which option works best for
                    you, sign this document, and return it to our office so we can prepare your renewal today.
                </p>
            </div>
        </div>
        <div class="flex justify-center my-6">
            <div class="form-field md:w-96">
                <div class="flex justify-between mb-2">
                    <div class="border-2 w-8/12 p-2">
                        <div class="ml-4">
                            <CheckboxInput label="6 Months" />
                        </div>
                    </div>
                    <div class="text-right border-2 w-4/12 p-2 bg-gray-200">
                        <p class="text-center">$850</p>
                    </div>
                </div>
                <div class="flex justify-between mb-2">
                    <div class="border-2 w-8/12 p-2">
                        <div class="ml-4">
                            <CheckboxInput label="8 Months" />
                        </div>
                    </div>
                    <div class="text-right border-2 w-4/12 p-2 bg-gray-200">
                        <p class="text-center">$840</p>
                    </div>
                </div>
                <div class="flex justify-between mb-2">
                    <div class="border-2 w-8/12 p-2">
                        <div class="ml-4">
                            <CheckboxInput label="10 Months" />
                        </div>
                    </div>
                    <div class="text-right border-2 w-4/12 p-2 bg-gray-200">
                        <p class="text-center">$830</p>
                    </div>
                </div>
                <div class="flex justify-between mb-2">
                    <div class="border-2 w-8/12 p-2">
                        <div class="ml-4">
                            <CheckboxInput label="12 Months" />
                        </div>
                    </div>
                    <div class="text-right border-2 w-4/12 p-2 bg-gray-200">
                        <p class="text-center">$825</p>
                    </div>
                </div>
                <div class="flex justify-between mb-2">
                    <div class="border-t-2 border-b-2 border-l-2 w-8/12 p-2">
                        <div class="ml-4">
                            <p class="mt-1">Discuss offer</p>
                        </div>
                    </div>
                    <div class="text-right border-t-2 border-b-2 border-r-2 w-4/12 p-2">
                        <div class="form-field">
                            <button
                                type="button"
                                class="btn btn-primary"
                            >
                                chat
                            </button>
                        </div>
                    </div>
                </div>
                <div class="flex border-2 justify-between mb-2 p-2">
                    <div class="ml-4">
                        <CheckboxInput label="Decline, I'm moving out" />
                    </div>
                </div>
            </div>
        </div>

        <div class="form-field">
            <div>
                <p>
                    Your current rental rate is 'CurrentRent'. If you do not renew your lease or give sixty (60) days written notice of your
                    intent to vacate your apartment, then your current lease will continue on a month-to-month basis. The rental rate for
                    the month-to-month will be the 12-month rate quoted above, plus a 'Month-to-MonthFee' monthly premium. This new rent
                    amount, plus your month-to-month premium will automatically begin when your current lease expires.
                </p>
            </div>
        </div>

        <div class="form-field">
            <div>
                <p>
                    We would like to remind you we value your input! If you have a specific request, concern or suggestion, please stop by
                    the office or leave us a note. If you would like to discuss this offer, you may contact us in person, via email, or by
                    phone. You can also access this offer through your Resident Portal at 'url'. There, you can take advantage of the built
                    in chat feature to discuss with us.
                </p>
            </div>
        </div>

        <div class="form-field">
            <div>
                <p>Thank you again for being a wonderful part of our community. We look forward to continuing our relationship!</p>
            </div>
        </div>

        <div class="form-field">
            <div class="border-t-2">
                <p class="font-bold mt-4">'Property Manager Name'</p>
                <p class="font-bold">Manager, 'Community Name'</p>
            </div>
            <div class="form-field mt-4 w-full">
                <button
                    type="button"
                    class="btn btn-primary w-full"
                >
                    submit
                </button>
            </div>
        </div>
    </Page>
</template>

<script>
import Page from '@/components/ui/Page';
import CheckboxInput from '@/components/ui/CheckboxInput';

export default {
    components: { Page, CheckboxInput },
};
</script>
