<template>
    <div class="page">
        <ContentArea
            :content-class="contentClass"
            :loading="contentLoading"
            :watch-for-scroll="watchForScroll"
            @reachedTop="$emit('reachedTop')"
            @reachedBottom="$emit('reachedBottom')"
            @scroll="$emit('scroll', $event)"
        >
            <template v-slot:header>
                <slot name="header">
                    <div class="flex flex-col pt-6">
                        <div
                            v-if="title"
                            class="flex items-start page-paddings"
                        >
                            <icon
                                v-if="backButton"
                                name="arrow-left"
                                class="flex-shrink-0 h-6 w-6 mt-1 mr-3 text-gray-800 cursor-pointer"
                                @click="back"
                            />
                            <div
                                class="page-title"
                                :class="{ [titleClass]: titleClass }"
                            >
                                {{ title }}
                            </div>
                        </div>
                        <slot name="header-addition"></slot>
                    </div>
                </slot>
            </template>

            <template v-slot:default>
                <slot
                    name="default"
                    :props="props"
                ></slot>
            </template>

            <template v-slot:footer>
                <slot name="footer"></slot>
            </template>
        </ContentArea>
        <Loader
            :loading="loading"
            :backdrop="true"
        />
    </div>
</template>

<script>
import ContentArea from '@/components/ui/ContentArea';
import Icon from '@/components/ui/Icon';
import Loader from '@/components/ui/Loader';

export default {
    components: { ContentArea, Icon, Loader },

    props: {
        title: {
            type: String,
        },

        titleClass: {
            type: String,
        },

        props: {
            type: Object,
        },

        contentClass: {
            type: String,
        },

        loading: {
            type: Boolean,
        },

        contentLoading: {
            type: Boolean,
        },

        watchForScroll: {
            type: Boolean,
        },

        customBackButton: {
            type: Boolean,
        },

        showBackButton: {
            type: Boolean,
        },
    },

    emits: ['back', 'reachedTop', 'reachedBottom', 'scroll'],

    computed: {
        backButton() {
            if (this.customBackButton) {
                return this.showBackButton;
            }

            const parentName = this.$route.meta.parent;

            if (!parentName) {
                return false;
            }

            const parent = this.$router.resolve({ name: parentName });

            return !!parent?.meta?.parent;
        },
    },

    methods: {
        back() {
            if (this.customBackButton) {
                this.$emit('back');
            } else {
                this.$router.back();
            }
        },

        close() {
            this.$router.replace({ name: this.$route.meta.parent });
        },
    },
};
</script>

<style scoped>
.page {
    @apply h-full;
}
</style>
